function ContactForm () {
  var isPhone = /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test( navigator.userAgent );

  var iHTML = "";

  var resetColors = function () {
    document.getElementById( "inputname" ).style.borderBottom = "1px solid rgba(237, 237, 237)";
    document.getElementById( "inputemail" ).style.borderBottom = "1px solid rgba(237, 237, 237)";
    document.getElementById( "inputcompany" ).style.borderBottom = "1px solid rgba(237, 237, 237)";
    document.getElementById( "inputzipcode" ).style.borderBottom = "1px solid rgba(237, 237, 237)";
    document.getElementById( "inputphone" ).style.borderBottom = "1px solid rgba(237, 237, 237)";
    document.getElementById( "inputcity" ).style.borderBottom = "1px solid rgba(237, 237, 237)";
    document.getElementById( "inputaddress" ).style.borderBottom = "1px solid rgba(237, 237, 237)";
    document.getElementById( "inputcountry" ).style.borderBottom = "1px solid rgba(237, 237, 237)";
    document.getElementById( "inputmessage" ).style.borderBottom = "1px solid rgba(237, 237, 237)";
  };

  var postFormCompleted = function ( response ) {
    var json = JSON.parse( response );
    //console.log( json );

    if (json.ERR == 1) {
      // There are errors
      for (var i = 0; i < json.errors.length; i++) {
        var err = json.errors[i];
        var item = err.item;
        var message = err.message;
        //todo show error
        console.log(err);
      }

      var button = document.getElementById( "sendtheform" );
      button.innerHTML = iHTML;
      button.addEventListener( "click", buttonClick );
    } else {
      console.log('sent');
      var successMessage = document.getElementById( "formsuccess" );
      successMessage.innerHTML = "Mesajınız başarılı bır şekilde gönderilmiştir.";
    }
  };

  var postForm = function () {

    var post = {
      name: document.getElementById( "inputname" ).value,
      email: document.getElementById( "inputemail" ).value,
      company: document.getElementById( "inputcompany" ).value,
      phone: document.getElementById( "inputzipcode" ).value,
      zipcode: document.getElementById( "inputphone" ).value,
      address: document.getElementById( "inputcity" ).value,
      city: document.getElementById( "inputaddress" ).value,
      country: document.getElementById( "inputcountry" ).value,
      message: document.getElementById( "inputmessage" ).value
    };

    var xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function () {
      if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
        postFormCompleted( xmlhttp.responseText );
      } else {
        if (!!xmlhttp.responseText) console.log( xmlhttp.responseText );
      }
    };
    xmlhttp.open( "POST", "/tr/form", true );
    xmlhttp.setRequestHeader( "Content-Type", "application/json;charset=UTF-8" );
    xmlhttp.send( JSON.stringify( post ) );
  };

  var destroyErrorRemove = function ( e ) {
    document.getElementsByTagName( "body" )[0].removeChild( e );
  };

  var buttonClick = function () {
    //console.log( "form: button click" );

    var button = document.getElementById( "sendtheform" );
    button.removeEventListener( "click", buttonClick );

    iHTML = button.innerHTML;
    button.innerHTML = "Lütfen bekleyiniz....";

    var hasError = false;
    var errorMessage = "";

    resetColors();

    setTimeout( function () {
      if (!document.getElementById( "inputname" ).value.length) {
        console.log( "theform: no name" );
        hasError = true;
        errorMessage = "Adınızı belirtmediniz";
        console.log("inputname");
        document.getElementById( "inputname" ).style.borderBottom = "1px solid red";
      }

      if (!document.getElementById( "inputphone" ).value.length) {
        console.log( "theform: no phone" );
        hasError = true;
        errorMessage = "Telefonunuzu belirtmediniz";
        console.log("inputphone");
        document.getElementById( "inputphone" ).style.borderBottom = "1px solid red";
      }

      function isValidEmailAddress(emailAddress) {
        var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
        return pattern.test(emailAddress);
      }

      if( !isValidEmailAddress( document.getElementById( "inputemail" ).value ) ) {
        console.log( "theform: no email" );
        hasError = true;
        errorMessage = "Eposta adresinizi belirtmediniz";
        console.log("inputemail");
        document.getElementById( "inputemail" ).style.borderBottom = "1px solid red";
      }

      if (!document.getElementById( "inputcompany" ).value.length) {
        console.log( "theform: no store" );
        hasError = true;
        errorMessage = "Firma belirtmediniz";
        console.log("inputcompany");
        document.getElementById( "inputcompany" ).style.borderBottom = "1px solid red";
      }

      if (!document.getElementById( "inputzipcode" ).value.length) {
        console.log( "theform: no store" );
        hasError = true;
        errorMessage = "Posta Kodu belirtmediniz";
        console.log("inputzipcode");
        document.getElementById( "inputzipcode" ).style.borderBottom = "1px solid red";
      }

      if (document.getElementById( "inputphone" ).value.length < 10) {
        console.log( "theform: no phone" );
        hasError = true;
        errorMessage = "Telefonunuzu belirtmediniz";
        console.log("inputphone");
        document.getElementById( "inputphone" ).style.borderBottom = "1px solid red";
      }

      if (!document.getElementById( "inputcity" ).value.length) {
        console.log( "theform: no city" );
        hasError = true;
        errorMessage = "Şehir belirtmediniz";
        console.log("inputcity");
        document.getElementById( "inputcity" ).style.borderBottom = "1px solid red";
      }

      if (!document.getElementById( "inputaddress" ).value.length) {
        console.log("theform: no address");
        hasError = true;
        errorMessage = "Adresinizi belirtmediniz";
        console.log("inputaddress");
        document.getElementById("inputaddress").style.borderBottom = "1px solid red";
      }

      if (!document.getElementById( "inputcountry" ).value.length) {
        console.log( "theform: no state" );
        hasError = true;
        errorMessage = "Ülke belirtmediniz";
        console.log("inputcountry");
        document.getElementById( "inputcountry" ).style.borderBottom = "1px solid red";
      }

      if (hasError) {
        button.innerHTML = iHTML;
        button.addEventListener( "click", buttonClick );
      } else {
        postForm();
      }
    }, 0 );
  };

  this.init = function () {
    //console.log( "form: init" );

    var button = document.getElementById( "sendtheform" );
    if (!!button) {
      button.addEventListener( "click", buttonClick );
      button.addEventListener( "touch", buttonClick );
    }
  }
}

window.onload = function () {
  var form = new ContactForm();
  form.init();
};